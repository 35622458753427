.Idea {
  display: grid;

  @media (min-width: 1050px) {
    grid-template-columns: 1.4fr 1fr;
  }

  gap: clamp(48px, calc(96vw / 7.68), 96px) 32px;

  header {
    > div {
      max-width: calc(586rem / 16);
    }

    .paragraph {
      margin: clamp(16px, calc(24vw / 7.68), 28px) 0 clamp(32px, calc(42vw / 7.68), 64px);
    }

    .claim {
      color: var(--primary-200);
    }

    .btn {
      margin: clamp(16px, calc(24vw / 7.68), 24px) 0 12px;
    }

    .additionalInfo {
      font-size: var(--small-font-size);
    }

    .countdown {
      margin-top: clamp(24px, calc(36vw / 7.68), 48px);
    }
  }

  .chartParagraph {
    color: var(--primary-200);
    margin-bottom: clamp(28px, calc(32vw / 7.68), 42px);
  }

  .chart {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    min-height: clamp(316px, calc(354vw / 7.68), 354px);
    overflow: hidden;

    .item {
      padding: clamp(12px, calc(16vw / 7.68), 16px) clamp(12px, calc(20vw / 7.68), 20px);
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        height: 100%;
        background: inherit;
        z-index: -1;
      }

      &:nth-child(1) {
        margin-top: 32px;
        background-color: var(--primary-700);
      }

      &:nth-child(2) {
        background-color: var(--primary-600);
      }

      &:nth-child(3) {
        background-color: var(--primary-800);
        padding-bottom: 48px;
      }

      &:nth-child(4) {
        margin-top: 48px;
        background: linear-gradient(90deg, var(--primary-800), var(--primary-700));
        box-shadow: 0px 0px 32px 0px rgba(29, 164, 253, 0.1);
      }

      .title {
        font-size: var(--small-font-size);
        margin-bottom: 4px;
      }

      .description {
        color: var(--secondary-500);
      }
    }
  }
}
