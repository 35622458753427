.RevealImage {
  display: grid;
  align-items: center;
  gap: clamp(48px, calc(64vw / 7.68), 78px);
  justify-content: center;
  @media (min-width: 1000px) {
    justify-content: space-between;
    grid-template-columns: auto auto;
  }
  header {
    max-width: calc(484rem / 16);
    h2 {
      margin-bottom: clamp(24px, calc(32vw / 7.68), 32px);
    }
    .paragraph {
      font-size: var(--small-font-size);
      > *:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
  .img {
    max-width: 410px;
    margin: 0 auto;
    position: relative;
    .CuttedEdgeIcon {
      position: absolute;
      right: -2px;
      top: -1px;
      width: auto;
      height: clamp(32px, calc(36vw / 7.68), 36px);
      z-index: 2;
    }
    .borderRight {
      height: calc(100% - clamp(32px, calc(36vw / 7.68), 36px) + 4px);
      top: calc(clamp(32px, calc(36vw / 7.68), 36px) - 2px);
    }
    .overlay {
      &:hover,
      &:has(input:focus-visible) {
        opacity: 0.8;
      }
      &:has(input:checked) {
        opacity: 0;
      }
      input {
        position: absolute;
        opacity: 0;
      }
      cursor: pointer;
      position: absolute;
      left: -1px;
      right: -1px;
      bottom: -1px;
      top: -1px;
      background: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(2px);
      display: flex;
      gap: 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .EyeIcon {
        filter: drop-shadow(0px 0px 24px #1da4fd);
        animation: blink 5s infinite;
        @keyframes blink {
          0%,
          25%,
          50%,
          75%,
          100% {
            opacity: 1;
          }
          20%,
          40%,
          60%,
          80% {
            opacity: 0.62;
          }
        }
      }
    }
  }
}
