.KeyDetails {
  display: grid;
  align-items: flex-start;
  gap: clamp(32px, calc(48vw / 7.68), 48px);
  @media (min-width: 1100px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .item {
    max-width: calc(608rem / 16);
    position: relative;
    padding: 16px 0 16px clamp(16px, calc(32vw / 7.68), 32px);
    h2 {
      margin-bottom: 8px;
    }
    .paragraph {
      font-size: var(--small-font-size);
      > *:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }
}
