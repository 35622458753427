.HeroImageBackground {
  position: relative;
  max-width: calc(2560rem / 16);
  header {
    max-width: calc(908rem / 16);
    margin: 0 auto;
    text-align: center;
    h1 {
      margin-bottom: 24px;
    }
    .paragraph {
      & > *:not(:last-child) {
        margin-bottom: 12px;
      }
      margin-bottom: 48px;
    }
  }
  .img {
    z-index: -1;
    max-width: clamp(408px, calc(408vw / 7.68), 520px);
    position: absolute;
    top: calc((clamp(82px, calc(96vw / 7.68), 124px) + 74px) * -1);
    right: calc(var(--pageMargin) * -1);
    @media (min-width: 1280px) {
      right: calc((min(100vw, 1920px) - 1200px) / -2);
    }
    transform: translateX(20%);
    @media (max-width: 1099px) {
      transform: translateX(40%);
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(90deg, var(--primary-900), rgba(0, 0, 255, 0) 50%, var(--primary-900)),
        linear-gradient(rgba(0, 0, 0, 0) 62%, var(--primary-900));
    }
  }
  @media (max-width: 999px) {
    position: relative;
    margin-bottom: 76%;
    .img {
      transform: translateX(var(--pageMargin));
      top: 80%;
      &::before {
        background: linear-gradient(90deg, var(--primary-900), rgba(0, 0, 0, 0) 50%),
          linear-gradient(var(--primary-900), rgba(0, 0, 0, 0), var(--primary-900));
      }
    }
  }
}
