.error {
  padding: 0 8px;
  font-size: clamp(calc(14rem / 16), calc(14vw / 7.68), calc(16rem / 16));
  color: var(--error-500);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 4px;
  line-height: inherit;
  overflow: hidden;
  svg {
    flex-shrink: 0;
    margin-top: 4px;
  }
}
