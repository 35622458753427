.HeroColumnWithImageReactor {
  display: grid;
  align-items: center;
  gap: clamp(64px, calc(82vw / 7.68), 96px) clamp(42px, calc(48vw / 7.68), 56px);
  @media (min-width: 1100px) {
    grid-template-columns: 1fr 1.4fr;
    .img {
      order: -1;
    }
  }
  header {
    max-width: calc(676rem / 16);
    .paragraph {
      margin: 24px 0 48px;
    }
    .additionalInfo {
      margin-top: 12px;
      font-size: var(--small-font-size);
    }
  }
  .img {
    margin: 0 auto;
    aspect-ratio: 1/1;
    max-width: 480px;
    max-height: 480px;
    position: relative;
    border-radius: 50%;
    border: 1px solid var(--secondary-500);
    box-shadow: 0px 0px 32px 0px rgba(29, 164, 253, 0.8), 0px 0px 12px 0px rgba(152, 214, 255, 0.6);
    &::before {
      content: '';
      width: 68%;
      height: 68%;
      position: absolute;
      left: 50%;
      bottom: 50%;
      transform: translate(-50%, 50%);
      border-radius: 200px;
      border-radius: 50%;
      background: #181f30;
    }
    .Elipse {
      padding: 16px;
      width: 100%;
      height: 100%;
      animation: Elipse 25s linear infinite;
      @keyframes Elipse {
        100% {
          transform: rotate(360deg);
        }
      }
    }
    img {
      width: 71%;
      height: unset;
      position: absolute;
      bottom: 16%;
      left: 50%;
      transform: translate(-53%, 1%);
    }
  }
}
