.Input {
  > p {
    margin-bottom: 2px;
    font-size: clamp(calc(14rem / 16), calc(16vw / 7.68), calc(16rem / 16));
  }
  input {
    height: 48px;
  }
  input,
  textarea {
    width: 100%;
    resize: none;
    padding: 12px;
    border: 1px solid var(--primary-700);
    background-color: var(--primary-800);
    &:focus-visible {
      outline: 0;
    }
    &:hover,
    &:focus-visible {
      border-color: var(--primary-500);
      background-color: var(--primary-700);
    }
    &:focus-visible {
      box-shadow: 0px 0px 32px 0px rgba(29, 164, 253, 0.1);
    }
  }
  &[aria-invalid='true'] {
    input,
    textarea {
      border-color: var(--error-500);
    }
  }
}
