.Reviews {
  position: relative;
  header {
    max-width: calc(586rem / 16);
    margin: 0 auto clamp(28px, calc(36vw / 7.68), 64px);
    text-align: center;
  }
  .list {
    list-style-type: none;
    @media (min-width: 900px) {
      column-count: 2;
      gap: clamp(16px, calc(20vw / 7.68), 32px);
    }
    li {
      break-inside: avoid;
      padding: clamp(20px, calc(24vw / 7.68), 24px) clamp(16px, calc(20vw / 7.68), 20px);
      border: 1px solid var(--primary-600);
      background-color: var(--primary-700);
      box-shadow: 0px 0px 32px 0px rgba(29, 164, 253, 0.1);
      margin: clamp(16px, calc(20vw / 7.68), 32px) 0;
      &:first-child,
      &:last-child {
        margin: 0;
      }
      .author {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 16px;
        align-items: center;
        margin-bottom: 24px;
        h3 {
          color: var(--primary-200);
        }
        .position {
          font-size: var(--small-font-size);
        }
      }
      .text {
        & > *:not(:last-child) {
          margin-bottom: 8px;
        }
      }
      .img {
        width: clamp(48px, calc(64vw / 7.68), 64px);
        height: clamp(48px, calc(64vw / 7.68), 64px);
        border-radius: 50%;
        border: 1px solid var(--secondary-500);
        background-color: var(--primary-800);
        box-shadow: 0px 0px 8px 0px rgba(152, 214, 255, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        svg {
          width: 62%;
          height: 62%;
          transform: translate3d(0, 0, 0);
          filter: drop-shadow(0px 0px 12px rgba(29, 164, 253, 0.3));
        }
      }
    }
  }
}
