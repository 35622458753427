.HeroWithRotatingElipsis {
  display: grid;
  align-items: center;
  gap: 32px;

  @media (min-width: 1050px) {
    grid-template-columns: 1.5fr 1fr;
  }

  header {
    z-index: 2;
    position: relative;
    pointer-events: none;

    a {
      pointer-events: auto;
    }

    > div {
      max-width: calc(586rem / 16);
    }

    .paragraph {
      margin: clamp(16px, calc(24vw / 7.68), 28px) 0 clamp(32px, calc(42vw / 7.68), 64px);
    }

    .claim {
      font-size: clamp(calc(20rem / 16), calc(28vw / 7.68), calc(28rem / 16));
    }

    .btn {
      margin: clamp(16px, calc(24vw / 7.68), 24px) 0 12px;
    }

    .additionalInfo {
      font-size: var(--small-font-size);
    }

    .countdown {
      margin-top: clamp(24px, calc(36vw / 7.68), 48px);
    }
  }

  .glow {
    position: absolute;
    right: 0;
    top: calc(clamp(62px, calc(80vw / 7.68), 98px) * -1);
    mix-blend-mode: screen;
    transform: translate3d(0, 0, 0);
    filter: blur(75px);
    pointer-events: none;
  }
}

.orbit {
  aspect-ratio: 1/1;
  margin: -10%;

  @media (min-width: 1050px) {
    margin: -50%;
  }

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    position: absolute;
    border-radius: 50%;
    box-shadow: 0px 0px 32px 0px rgba(29, 164, 253, 0.06);

    &:nth-child(1) {
      width: 100%;
      height: 100%;
      border: 1px solid var(--primary-800);
      background-color: var(--primary-900);
      opacity: 0.4;
    }

    &:nth-child(2) {
      width: 76%;
      height: 76%;
      border: 1px solid var(--primary-700);
      background-color: var(--primary-800);
      animation: rotate 20s linear infinite;

      .product {
        &:nth-child(1) {
          animation: product1 20s linear infinite reverse;
        }

        &:nth-child(2) {
          animation: product2 20s linear infinite reverse;
        }

        @keyframes product1 {
          0% {
            transform: rotate(0deg) scale(0.6);
          }

          25% {
            transform: rotate(90deg) scale(1);
          }

          50% {
            transform: rotate(180deg) scale(0.6);
          }

          100% {
            transform: rotate(360deg) scale(0.6);
          }
        }

        @keyframes product2 {
          0% {
            transform: rotate(0deg) scale(0.6);
          }

          50% {
            transform: rotate(180deg) scale(0.6);
          }

          75% {
            transform: rotate(270deg) scale(1);
          }

          100% {
            transform: rotate(360deg) scale(0.6);
          }
        }
      }
    }

    &:nth-child(3) {
      width: 52%;
      height: 52%;
      border: 1px solid var(--primary-600);
      background-color: var(--primary-700);
      animation: rotate 15s -5s linear infinite;

      .product {
        animation: product3 15s -5s linear infinite reverse;

        @keyframes product3 {
          0% {
            transform: rotate(0deg) scale(0.6);
          }

          25% {
            transform: rotate(90deg) scale(1);
          }

          50% {
            transform: rotate(180deg) scale(0.6);
          }

          100% {
            transform: rotate(360deg) scale(0.6);
          }
        }
      }
    }

    &.decorative {
      pointer-events: none;
      width: 100%;
      height: 100%;
      box-shadow: none;

      > div {
        position: absolute;
        width: 6px;
        height: 6px;
        filter: blur(2px) drop-shadow(0px 0px 8px #1da4fd);
        border-radius: 50%;
        background-color: var(--secondary-600);
        animation: glow 10s infinite;

        @keyframes glow {
          0%,
          25%,
          50%,
          75%,
          100% {
            opacity: 1;
          }

          20%,
          40%,
          60%,
          80% {
            opacity: 0;
          }
        }

        &:nth-child(1) {
          left: 25%;
          top: 25%;
        }

        &:nth-child(2) {
          left: 70%;
          top: 45%;
        }

        &:nth-child(3) {
          left: 15%;
          top: 80%;
        }

        &:nth-child(4) {
          left: 50%;
          top: 70%;
        }

        &:nth-child(5) {
          left: 90%;
          top: 90%;
        }
      }
    }

    &.img {
      width: 30%;
      height: 30%;
      border-radius: 50%;
      border: 1px solid var(--secondary-600);
      background-color: var(--primary-800);
      box-shadow: 0px 0px 34px 0px rgba(29, 164, 253, 0.3);
      position: relative;

      > div {
        overflow: hidden;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 115%;
        border-radius: 200px;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: bottom;
        }
      }
    }

    .product {
      position: absolute;
      width: 124px;
      height: 124px;
      border-radius: 50%;
      border: 1px solid var(--primary-900);
      background: var(--primary-800);

      &:first-child {
        left: calc(50% - 62px);
        top: -62px;
      }

      &:last-child {
        left: calc(50% - 62px);
        bottom: -62px;
      }

      > div {
        position: absolute;
        width: 100%;
        height: 150%;
        overflow: hidden;
        border-radius: 100px;
        bottom: 0;
        left: 0;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: bottom;
          margin-top: 10%;
        }
      }
    }
  }

  &:hover {
    > div {
      &:not(.decorative) {
        animation-play-state: paused;
      }

      .product {
        animation-play-state: paused !important;
      }
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
