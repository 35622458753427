.SimpleCtaSection {
  header {
    max-width: calc(586rem / 16);
    margin: 0 auto;
  }
  text-align: center;
  .cta {
    margin: 24px auto 0;
  }
  .additionalInfo {
    max-width: calc(486rem / 16);
    margin: 12px auto 0;
    font-size: var(--small-font-size);
  }
  .countdown {
    margin: clamp(24px, calc(36vw / 7.68), 48px) auto 0;
  }
}
